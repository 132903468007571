import './App.css';
import { useState, useEffect } from 'react';
import PasarelaPago from './Layout/PasarelaPago';
import Error from './components/Error';
import Pagado from './components/Pagado';
import { desencryptURL, createProductosObj } from './utils/helper';
import { getDataLigas, getDataPedido, getDataDetallePedido } from './api/pagoPasarela';
import { getProductosDescripciones } from './api/productoService';
import { validLiga } from './utils/helper';
import { loadOpenPay, loadAntiFraud } from './api/openPay';
import { getDataTipoSucursal } from './api/sucursalesService';
import { Token } from './api/token';
//import { credentials } from './credentials/credentialsToken';

/**
 * Metodo principal al ingresar a la app, valida información del parametro enviado por url
 * @returns Arreglo de objetos con información necesaria para ingresar al formulario
 */
const mainFunction = async () => {
  const valores = window.location.search;
  if (valores.length > 0) {
    if (valores.indexOf("?id=") === 0) {
      return "pagado";
    }
    let responseCrypt = await desencryptURL(valores);
    if (responseCrypt != false) {
      let dataReturned = [];
      let responseDataLiga = await getDataLigas(responseCrypt);
      if (responseDataLiga.data.data.estatusId == 2) {
        return "pagado";
      } else {
        let ligavalidada = await validLiga(responseDataLiga.data.data);
        if (ligavalidada == true) {
          let responseDataPedido = await getDataPedido(responseDataLiga.data.data.pedidoPasarelaId);
          let sucursal = await getDataTipoSucursal(responseDataPedido.data.data.sucursalId);
          dataReturned.push(responseDataLiga.data.data);
          dataReturned.push(responseDataPedido.data.data);
          let responseDataPedidoDetalle = await getDataDetallePedido(responseDataLiga.data.data.pedidoPasarelaId);
          let dataPedidoDetalle = [];
          dataPedidoDetalle = responseDataPedidoDetalle.data.data;
          let productosId = [];
          dataPedidoDetalle.forEach(element => {
            productosId.push(element.productoId);
          });
          let detalleproducto = await getProductosDescripciones(productosId);
          let objResumenProducto = await createProductosObj(dataPedidoDetalle, detalleproducto);
          dataReturned.push(objResumenProducto);
          dataReturned.push(sucursal.data.data);
          return dataReturned;
        } else {
          return false;
        }
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

/**
 * Metodo para obtener un Token del servicio,
 * @returns token en sessionStorage para uso en las demás peticiónes
 */
const token = async () => {
  var myToken = sessionStorage.getItem("tokenBearer");
  if (myToken == null) {
    var responseToken = await Token();
    if (responseToken.data.succeeded) {
      sessionStorage.setItem("tokenBearer", responseToken.data.accessToken);
    } else {
      sessionStorage.setItem("tokenBearer", false);
    }
  }
}

/**
 * Funcion que obtiene el arreglo de objetos del metodo principal mediante un effect
 * @returns Componente principal PasarelaPago o effect que muestra componente de Error o Pagado 
 */
const App = () => {
  let [datalog, dataLog] = useState();
  let [error, errorP] = useState();

  //Recuperar el id del cargo de la url para validar estatus del pago = Completado
  const idCargoOpenpay = window.location.search;

  useEffect(() => {
  /**
   * Función asíncrona que maneja la lógica de autenticación y recuperación de datos
   * para el procesamiento de pagos. Verifica si existe un token de sesión y realiza
   * la operación correspondiente en función de la respuesta obtenida.
   * 
   * @async
   * @function dataLogResponse
   * @returns {Promise<void>}
   */
    const dataLogResponse = async () => {
      try {
        loadOpenPay(false);
        loadAntiFraud(false);
        // Obtiene el token de autenticación
        await token();

      /**
       * Verifica si el token de sesión está disponible en sessionStorage.
       * Si no está presente, muestra un mensaje de error.
      */
        const tokenBearer = sessionStorage.getItem("tokenBearer");
        if (!tokenBearer) {
          errorP(<Error />);
          return;
        }
  
      /**
       * Llama a la función principal para verificar el estado del pago.
       * @constant {string|boolean} response - Estado del pago obtenido de `mainFunction`.
       * Si es "pagado", muestra un componente de confirmación de pago;
       * si es `true`, registra el dato en el log; en caso contrario, muestra un mensaje de error.
      */
        const response = await mainFunction();
        if (response === "pagado") {
          errorP(<Pagado idCargo={idCargoOpenpay} />);
        } else if (response) {
          dataLog(response);
        } else {
          errorP(<Error />);
        }
      } catch (error) {
        console.error("Error en dataLogResponse:", error);
        errorP(<Error />);
      }
    };
  
    dataLogResponse();
  }, []);
  

  return (
    <div>
      {datalog ? <PasarelaPago data={datalog} /> : error}
    </div>
  );
}

export default App;