import axios from 'axios';

/**
 * Metodo para obtener un token de logeo de app hacia mvc
 * @param {} Token 
 * @returns object con token
 */
export const Token = async () => {
    try {
        const credentials = {
        Email: process.env.REACT_APP_TOKEN_EMAIL,
        Contrasena: process.env.REACT_APP_TOKEN_PWD
      };
  
      const response = await axios.post(process.env.REACT_APP_TOKEN, credentials,);
      return response;
    } catch (error) {
      console.error('Error obtaining token:', error.message);
      return false;
    }
  };