import axios from "axios";
import loadScript, { loadAndSetScript } from '../utils/helper';
import { actualizaLiga } from './pagoPasarela';

const head = typeof document !== 'undefined' ? document.querySelector('head') : '';

const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem("tokenBearer")}` }
};

/**
 * Carga de datos para uso de api Openpay
 * @param {} loadOpenPay 
 * @returns boolean
 */
export const loadOpenPay = setOpenPayLoaded => {
  if (!document.querySelector('#openPayInstance')) {
    loadScript('https://js.openpay.mx/openpay.v1.min.js', head, 'openPayInstance');
    document.querySelector('#openPayInstance').addEventListener('load', () => {
      const openpay = window.OpenPay;
      openpay.setId(process.env.REACT_APP_MERCHANT_ID);
      openpay.setApiKey(process.env.REACT_APP_APIKEY_OPENPAY);
      openpay.setSandboxMode(process.env.REACT_APP_SANDBOX_OPENPAY === 'development' ? true : false);
      return true;
    });
  }
};

/**
 * Carga de datos para uso de api sistema antifraude
 * @param {} setAntiFraudLoaded 
 * @returns boolean
 */
export const loadAntiFraud = setAntiFraudLoaded => {
  if (!document.querySelector('#openPayAntiFraud')) {
    loadScript('https://js.openpay.mx/openpay-data.v1.min.js', head, 'openPayAntiFraud');
    document.querySelector('#openPayAntiFraud').addEventListener('load', () => {
      loadAndSetScript(
        `var deviceSessionId = OpenPay.deviceData.setup(
                            "paymentFormId", "hiddenDevice"
                          );`,
        head,
        'deviceSessionid'
      );
      return true;
    });
  }
};

/**
 * Metodo para la creación de un token mediante Api Openpay
 * @param {informacionTarjeta,setTextModalValidate,data} crearToken 
 * @returns object con token y datos de cliente
 */
export const crearToken = async (informacionTarjeta, setTextModalValidate, data) => {
  let newCardValidators = window.OpenPay.card;
  let newOpenPay = window.OpenPay;
  var numTarjeta = informacionTarjeta.numeroTarjeta.replace(/\s+/g, '');
  let splitFechaTarjeta = informacionTarjeta.fechaTarjeta.split("/");
  let fechaTarjetaMes = splitFechaTarjeta[0];
  let fechaTarjetaAño = splitFechaTarjeta[1];
  var responsecargo = "";

  if (newCardValidators.validateCardNumber(numTarjeta) && newCardValidators.validateCVC(informacionTarjeta.ccv)) {
    const newToken = {
      "card_number": numTarjeta,
      "holder_name": informacionTarjeta.nombreTarjeta,
      "expiration_year": fechaTarjetaAño,
      "expiration_month": fechaTarjetaMes,
      "cvv2": informacionTarjeta.ccv
    }
    try{
    await newOpenPay.token.create(
      newToken,
      async function (response, body, error) {
        let arr = informacionTarjeta.nombreTarjeta.split(' ');
        var InfoCargo = {
          customer: {
            "name": arr[0] + " " + arr[1],
            "lastName": arr[2] + " " + arr[3],
            "phoneNumber": "",
            "email": data.data[0].correo
          },
          customerId: null,
          token: response.data.id,
          source_id: response.data.id,
          idSession: "0",
          idOrder: "CRM-" + data.data[1].pedidoId + "-" + data.data[1].sucursalId +"-"+ data.data[0].id + "-" + data.data[1].origenId,
          deviceSessionId: null,
          method: "card",
          amount: data.data[1].monto,
          currency: "MXN",
          description: 'Prueba de pago Autex tarjeta',
          origenId: 0,
          tarjetaId: 0,
          secure: true,
          providerCardId: "",
          pedidoId: 0,
          numeroTarjeta: "",
          clienteId: 0
        };

        responsecargo = await envioPago(InfoCargo, setTextModalValidate, data, informacionTarjeta, response);
      });
    return true;
    }
    catch(error){
      setTextModalValidate('Estamos experaa asimentando problemas con este método de pago, lamentamos las molestias que esto le ocasiona. ' +
      'Inténtalo de nuevo más tarde o cambia el método de pago.');
    }
  } else {
    return false;
  }
}

/**
 * Metodo para la realización del cargo al token creado previamente
 * @param {cliente,pedido,monto,correo,tipoSucursal} envioPago 
 * @returns object con token y datos de cliente
 */
export const envioPago = async (dataPago, setTextModalValidate, data) => {
  setTextModalValidate(false);
  var idliga= data.data[0].id;
  return await axios.post(process.env.REACT_APP_OPENPAY_PAYMENTS, dataPago, config).then((res) => {
    if (res.data.data != null && res.data.data.exito === true) {
      window.location.href = process.env.REACT_APP_SANDBOX+`${process.env.REACT_APP_MERCHANT_ID}/charges/${res.data.data.id}/redirect`
    }
    if (res.data.error.mensaje) {

      if(res.data.error.mensaje.includes("The order_id has already been processed")){
        try{
          actualizaLiga(idliga, 3);
        }catch(error){
        }
        window.location.reload();
      }else{
        setTextModalValidate(res.data.error.mensaje);
      }
    }
    return res;
  });
}

/**
 * Metodo para obtener el estatus del cargo
 * @param {idCargo} recuperarCargoOpenPay 
 * @returns object con token y datos de cliente
 */
export const recuperarCargoOpenPay = async (idCargo) => {
  let response = null;
  try {
    await axios.get(process.env.REACT_APP_OPENPAY_CHARGES+idCargo, config).then((res) => {
      response = res.data
    });
  } catch {
    response = null;
  }
  return response;
}

